import { Link } from "@remix-run/react"
import { ComponentType, useMemo } from "react"
import { twMerge } from "tailwind-merge"

import Spinner from "~/icons/Spinner"

export type ButtonSize = "small" | "medium" | "large" | "xlarge" | "2xlarge"

export default function LozengeButton({
  buttonIntent,
  className: classNameParam,
  hideTextWhenSmall = false,
  Icon,
  isLoading = false,
  preventScrollReset = false,
  reloadDocument = false,
  size = "medium",
  submit = false,
  text,
  toRoute,
  onClick,
}: {
  buttonIntent?: string
  className: string
  hideTextWhenSmall?: boolean
  Icon?: ComponentType<{ className: string }>
  isLoading?: boolean
  preventScrollReset?: boolean
  reloadDocument?: boolean
  size?: ButtonSize
  submit?: boolean
  text?: string
  toRoute?: string
  onClick?: () => void
}) {
  const iconSize = useMemo(() => {
    switch (size) {
      case "small":
        return "size-3 md:size-4"
      case "medium":
        return "size-4 md:size-5"
      case "large":
        return "size-5 md:size-6"
      case "xlarge":
        return "size-6 md:size-7"
      case "2xlarge":
        return "size-7 md:size-8"
    }
  }, [size])

  const fontSizeClasses = useMemo(() => {
    switch (size) {
      case "small":
        return "text-xs md:text-sm"
      case "medium":
        return "text-sm md:text-base"
      case "large":
        return "text-base md:text-lg"
      case "xlarge":
        return "text-lg md:text-xl"
      case "2xlarge":
        return "text-xl md:text-2xl"
    }
  }, [size])

  const gapClasses = useMemo(() => {
    switch (size) {
      case "small":
        return "gap-x-1 md:gap-x-1.5"
      case "medium":
        return "gap-x-1 md:gap-x-2"
      case "large":
        return "gap-x-1.5 md:gap-x-2.5"
      case "xlarge":
        return "gap-x-2 md:gap-x-3"
      case "2xlarge":
        return "gap-x-3 md:gap-x-4"
    }
  }, [size])

  const horizontalPaddingClasses = useMemo(() => {
    if (!Icon) {
      switch (size) {
        case "small":
          return "px-3"
        case "medium":
          return "px-4"
        case "large":
          return "px-5"
        case "xlarge":
          return "px-6"
        case "2xlarge":
          return "px-7"
      }
    }

    if (!text) {
      switch (size) {
        case "small":
          return "px-1"
        case "medium":
          return "px-1.5"
        case "large":
          return "px-2"
        case "xlarge":
          return "px-2.5"
        case "2xlarge":
          return "px-3"
      }
    }

    if (hideTextWhenSmall) {
      return "pl-2 pr-2 md:pl-3 md:pr-4"
    } else {
      return "pl-2 pr-3 md:pl-3 md:pr-4"
    }
  }, [hideTextWhenSmall, Icon, size, text])

  const verticalPaddingClasses = useMemo(() => {
    return "py-0.5 md:py-1.5"
  }, [])

  const className = useMemo(() => {
    return twMerge(
      "flex flex-row items-center justify-center rounded-lg cursor-pointer",
      fontSizeClasses,
      gapClasses,
      horizontalPaddingClasses,
      verticalPaddingClasses,
      classNameParam
    )
  }, [
    classNameParam,
    fontSizeClasses,
    gapClasses,
    horizontalPaddingClasses,
    verticalPaddingClasses,
  ])

  const iconElement = useMemo(() => (Icon ? <Icon className={iconSize} /> : null), [Icon, iconSize])

  const textElement = useMemo(
    () =>
      text ? (
        <p className={[hideTextWhenSmall ? "hidden md:block" : "", "pb-0.5"].join(" ")}> {text}</p>
      ) : null,
    [hideTextWhenSmall, text]
  )

  const linkBody = useMemo(() => {
    if (isLoading) {
      return <Spinner className={iconSize} />
    }

    return (
      <>
        {iconElement}
        {textElement}
      </>
    )
  }, [iconElement, iconSize, isLoading, textElement])

  const commonProps = {
    className,
    onClick,
  }

  if (toRoute) {
    return (
      <Link
        {...commonProps}
        preventScrollReset={preventScrollReset}
        reloadDocument={reloadDocument}
        to={toRoute}
      >
        {linkBody}
      </Link>
    )
  } else {
    return (
      <button
        {...commonProps}
        disabled={isLoading}
        {...(buttonIntent ? { name: "intent", value: buttonIntent } : {})}
        type={submit ? "submit" : undefined}
      >
        {linkBody}
      </button>
    )
  }
}
